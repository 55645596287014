import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import store from "./store";
import VueAxios from "vue-axios";
import axios from "axios";
import VueMoment from "vue-moment";
import moment from "moment-timezone";

Vue.config.productionTip = false;

require("moment/locale/de");
moment.locale("de");
Vue.use(VueMoment, { moment });

const app = new Vue({
    router,
    vuetify,
    store,
    render: (h) => h(App),
});

axios
    .get(window.location.origin + "/config.php")
    .then((res) => {
        if (res.data.api_base) Vue.use(VueAxios, axios.create({ baseURL: res.data.api_base }));
        else throw new Error("api_base not found");
    })
    .catch(() => {
        console.warn("api_base or frontend not found");
        Vue.use(VueAxios, axios.create({ baseURL: process.env.VUE_APP_API_HOST }));
    })
    .finally(() => app.$mount("#app"));
