import Answer from "../formular/framework/Answer";
import { dependFunction } from "../formular/QuestionFormular";

export default {
    props: {
        formular: { type: Answer, required: true },
        context: { type: Array, required: true },
    },
    data() {
        return {
            value: undefined,
        };
    },
    computed: {
        attrs() {
            return this.formular.render();
        },
        display() {
            if (this.attrs.depends) return this.attrs.depends(dependFunction(this.context));
            return true;
        },
        state() {
            return this.compare(this.formular) && this.display;
        },
        linkedValue() {
            return this.formular.data;
        },
    },
    watch: {
        value: {
            deep: true,
            handler(value) {
                if (value != this.formular.data || typeof value == "object") {
                    this.formular.data = value;
                    if (this.formular.options?.onchange) this.formular.options.onchange(dependFunction(this.context), this.context, value);
                    this.$nextTick(() => this.$emit("update"));
                }
            },
        },
        state(value, oldValue) {
            if (oldValue && !value) {
                if (this.formular.data != undefined) this.value = this.formular.data;
                else this.value = this.formular.default(this.context);
            }
        },
        linkedValue: {
            immediate: true,
            handler(value) {
                this.value = value;
            },
        },
    },
    mounted() {
        if (this.formular.data != undefined) this.value = this.formular.data;
        else if (this.formular.default != undefined) this.value = this.formular.default(this.context);
    },
};
