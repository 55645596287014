import Answer, { AnswerOption } from "./Answer";
import TextfieldAnswerComponent from "../components/TextfieldAnswer.vue";

export default class TextfieldAnswer extends Answer {
    constructor(id: string, options: TextfieldAnswerOptions) {
        super(id, options, TextfieldAnswerComponent);
    }
}

export interface TextfieldAnswerOptions extends AnswerOption {
    type?: string;
    label?: string;
    hint?: string;
    placeholder?: string;
}
