<script>
import { mapMutations } from "vuex";

import Layout from "@layouts/main.layout";
import ContainerLayout from "@layouts/container.layout";

export default {
    name: "FormularView",
    components: {
        Layout,
        ContainerLayout,
    },
    computed: {
        formular_id() {
            return this.$route.params.id;
        },
        origin() {
            return window.location.origin;
        },
    },
    mounted() {
        window.addEventListener("message", this.handleEvent);
    },
    beforeDestroy() {
        window.removeEventListener("message", this.handleEvent);
    },
    methods: {
        ...mapMutations({
            logMessage: "ui/log",
            logError: "ui/error",
        }),
        save() {
            window.frames["question"].postMessage({ type: "save" }, "*");
        },
        saved() {
            this.logMessage("Formular wurde gespeichert");
        },
        handleEvent(message) {
            if (message.data?.id == this.formular_id) {
                if (message.data.type == "save") this.saved();
                if (message.data.type == "error") this.logError("Das Formular konnte nicht gespeichert werden");
            }
        },
    },
};
</script>

<template>
    <Layout title="Schnittstellenmerkmale">
        <template #navbar.append>
            <v-spacer class="mobile" />
            <v-btn dark text @click="save"><v-icon left>mdi-send</v-icon>Speichern</v-btn>
        </template>
        <ContainerLayout>
            <iframe
                name="question"
                :src="origin + '/forms/question/' + formular_id + '?notification=true'"
                frameborder="0"
                width="100%"
                height="100%"
            />
        </ContainerLayout>
    </Layout>
</template>

<style lang="scss" scoped></style>
