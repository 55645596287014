<script>
import ListfieldAnswer from "../framework/ListfieldAnswer";

import answerMixin from "@mixin/answer.mixin";

export default {
    name: "ListfieldAnswerComponent",
    mixins: [answerMixin],
    mounted() {
        if (this.value != undefined && !Array.isArray(this.value)) this.value = [this.value];
        if (this.value == undefined) this.value = [];
    },
    methods: {
        compare(instance) {
            return instance instanceof ListfieldAnswer;
        },
        checkIndex(index, value) {
            if (value == "" || value == undefined) this.value.splice(index, 1);
        },
        getRules(index) {
            if (index < this.value.length) return this.attrs.rules;
            return [];
        },
    },
};
</script>

<template>
    <div v-if="state" class="listfield">
        <template v-if="value && Array.isArray(value)">
            <v-text-field v-model="value[0]" outlined persistent-hint v-bind="attrs" />
            <v-text-field
                v-for="index in value.length"
                :key="index"
                v-model="value[index]"
                outlined
                persistent-hint
                v-bind="attrs"
                :label="attrs.label_alt || attrs.label"
                :rules="getRules(index)"
                @input="checkIndex(index, $event)"
            />
        </template>
        <template v-else>Es ist ein Fehler aufgetreten</template>
    </div>
</template>

<style lang="scss" scoped>
.listfield {
    display: flex;
    flex-direction: column;

    * {
        flex: 1;
    }
}
</style>
