<script>
import axios from "axios";

import ComboboxAnswer from "../framework/ComboboxAnswer";

import answerMixin from "@mixin/answer.mixin";

export default {
    name: "ComboboxAnswerComponent",
    mixins: [answerMixin],
    data() {
        return {
            items: [],
        };
    },
    mounted() {
        if (typeof this.attrs.items == "function") {
            const items = this.attrs.items;
            axios.get(window.location.origin + "/config.php").then((res) => {
                items(axios.create({ baseURL: res.data.api_base || process.env.VUE_APP_API_HOST })).then((res) => (this.items = res));
            });
        } else this.items = this.attrs.items;
    },
    methods: {
        compare(instance) {
            return instance instanceof ComboboxAnswer;
        },
    },
};
</script>

<template>
    <v-combobox v-if="state" v-model="value" outlined persistent-hint v-bind="attrs" :items="items" />
</template>

<style lang="scss" scoped></style>
