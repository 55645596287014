import Answer, { AnswerOption } from "./Answer";
import ComboboxAnswerComponent from "../components/ComboboxAnswer.vue";

import { AxiosInstance } from "axios";

export default class ComboboxAnswer extends Answer {
    constructor(id: string, options: ComboboxAnswerOptions) {
        super(id, options, ComboboxAnswerComponent);
    }
}

export interface ComboboxAnswerOptions extends AnswerOption {
    items: Array<string | number> | ((axios: AxiosInstance) => Promise<Array<string>>);
    label?: string;
    hint?: string;
    multiple?: boolean;
    chips?: boolean;
    type?: string;
    "deletable-chips"?: boolean;
}
