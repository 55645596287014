import Answer, { AnswerOption } from "./Answer";
import ListfieldAnswerComponent from "../components/ListfieldAnswer.vue";

export default class TextfieldAnswer extends Answer {
    constructor(id: string, options: ListfieldAnswerOptions) {
        super(id, options, ListfieldAnswerComponent);
    }
}

export interface ListfieldAnswerOptions extends AnswerOption {
    type?: string;
    label?: string;
    label_alt?: string;
    hint?: string;
    placeholder?: string;
}
