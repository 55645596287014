import Answer, { AnswerOption } from "./Answer";
import SelectAnswerComponent from "../components/SelectAnswer.vue";

export default class SelectAnswer extends Answer {
    constructor(id: string, options: SelectAnswerOptions) {
        super(id, options, SelectAnswerComponent);
    }
}

export interface SelectAnswerOptions extends AnswerOption {
    items: Array<SelectItem>;
    label?: string;
    hint?: string;
}

export interface SelectItem {
    key: string;
    value: any;
}
