<script>
import RowAnswer from "../framework/RowAnswer";

import answerMixin from "@mixin/answer.mixin";

export default {
    name: "RowAnswerComponent",
    mixins: [answerMixin],
    methods: {
        compare(instance) {
            return instance instanceof RowAnswer;
        },
    },
};
</script>

<template>
    <div v-if="state" class="row-answer">
        <component
            :is="item.component"
            v-for="(item, index) in attrs.items"
            :key="index"
            :formular="item"
            :context="context"
            @update="$emit('update')"
        />
    </div>
</template>

<style lang="scss" scoped>
.row-answer {
    display: flex;
    gap: 1rem;

    * {
        flex: 1;
    }
}

@media screen and (max-width: 1000px) {
    .row-answer {
        flex-direction: column;
    }
}
</style>
