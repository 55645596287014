<script>
import { mapState } from "vuex";

import Loading from "@components/_loading.vue";

export default {
    name: "MainLayout",
    components: {
        Loading,
    },
    props: {
        loading: { type: Boolean, default: undefined },
        title: { type: String, default: undefined },
    },
    data() {
        return {
            isLoading: true,
        };
    },
    computed: {
        ...mapState({
            account: (state) => state.auth.account,
        }),
        avatarText() {
            if (!this.account) return "A";
            if (!this.account.data) return this.account.username.substring(0, 2).toUpperCase();
            if (!this.account.data.short) return this.account.username.substring(0, 2).toUpperCase();
            return this.account.data.short.toUpperCase();
        },
    },
    watch: {
        loading(value) {
            this.isLoading = value;
        },
    },
    mounted() {
        if (this.loading == undefined) this.isLoading = false;
    },
};
</script>

<template>
    <div class="main-layout">
        <div class="navbar">
            <img src="@assets/Fallegger_white.png" />
            <div class="nav">
                <slot name="navbar" />
            </div>
            <div class="nav-mini">
                <slot name="navbar.mini" />
            </div>
            <v-spacer class="desktop" />
            <div class="append">
                <slot name="navbar.append" />
            </div>
            <div class="nav-title text-button">{{ title }}</div>
        </div>
        <div v-if="!isLoading" class="content">
            <slot />
        </div>
        <Loading v-else />
    </div>
</template>

<style lang="scss" scoped>
.main-layout {
    width: 100vw;
    height: 100vh;
    display: grid;
    grid-template-rows: $navbar-height calc(100vh - $navbar-height);
    overflow: hidden;

    .navbar {
        position: relative;
        display: flex;
        background: $primary;
        align-items: center;
        padding: 0 1rem;

        .nav {
            display: flex;
        }

        .nav-mini {
            display: none;
        }

        img {
            height: 60%;
            margin: 0 2rem 0 1rem;
        }

        img.medium {
            display: none;
        }

        .append {
            height: 100%;
            display: flex;
            align-items: center;
        }

        .nav-title {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            top: 0;
            left: 50%;
            height: $navbar-height;
            transform: translateX(-50%);
            color: white;
        }
    }

    .content {
        display: contents;
    }
}

@media only screen and (max-width: 960px) {
    .main-layout {
        .navbar {
            img {
                content: url("@assets/Fallegger_white_small.png");
                height: 40%;
            }
        }
    }
}

@media only screen and (max-width: 775px) {
    .main-layout {
        .navbar {
            img {
                display: none;
            }
        }
    }
}

@media only screen and (max-width: 650px) {
    .main-layout {
        .navbar {
            padding: 0;

            .nav {
                display: none;
            }

            .nav-mini {
                display: unset;
            }

            .append {
                width: 100%;
                margin: 0 1rem;
            }

            .nav-title {
                display: none;
            }
        }
    }
}
</style>
