import Answer, { AnswerOption } from "./Answer";
import TextareaAnswerComponent from "../components/TextareaAnswer.vue";

export default class TextareaAnswer extends Answer {
    constructor(id: string, options: TextareaAnswerOptions) {
        super(id, options, TextareaAnswerComponent);
    }
}

export interface TextareaAnswerOptions extends AnswerOption {
    type?: string;
    label?: string;
    hint?: string;
    placeholder?: string;
    rows?: number;
}
