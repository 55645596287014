import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
    iconfont: "fa",
    theme: {
        dark: false,
        themes: {
            light: {
                primary: "#0099D4",
                accent: "#A1A0A4",
                success: "#4CAF50",
                warning: "#FFC107",
                info: "#2196F3",
                error: "#FF5252",
            },
        },
    },
});
