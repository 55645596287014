<script>
import questionFormular, { dependFunction, getNext, getPrevious, getData, mapData } from "../../formular/QuestionFormular";

export default {
    name: "QuestionFormComponent",
    props: {
        disabled: { type: Boolean, default: false },
    },
    data() {
        return {
            state: 1,
            formular: [],
            updateIndex: 0,
        };
    },
    computed: {
        context() {
            return this.formular.map((question) => question.answer);
        },
    },
    watch: {
        state: {
            immediate: true,
            handler(value) {
                setTimeout(() => {
                    if (document.querySelector(`#qstep-${value}`)) document.querySelector(`#qstep-${value}`).scrollIntoView({ behavior: "smooth" });
                }, 450);
            },
        },
    },
    mounted() {
        this.formular = questionFormular;
        this.update();
    },
    methods: {
        setData(data) {
            mapData(this.formular, data);
            this.update();
            this.updateIndex++;
        },
        dependFunction() {
            return dependFunction(this.context);
        },
        getNext() {
            return getNext(this.formular, this.state);
        },
        getPrevious() {
            return getPrevious(this.formular, this.state);
        },
        update() {
            this.$emit("input", getData(this.formular));
        },
    },
};
</script>

<template>
    <div :key="updateIndex">
        <v-stepper :key="formular.length" v-model="state" vertical flat height="100%" :class="{ disabled }">
            <div v-for="(question, index) in formular" :id="`qstep-${index + 1}`" :key="index">
                <template v-if="question.depends == undefined || question.depends(dependFunction())">
                    <v-stepper-step editable error-icon="mdi-alert-circle" :step="index + 1" :rules="[() => question.form]">
                        {{ question.title }}
                    </v-stepper-step>
                    <v-stepper-content :step="index + 1">
                        <v-form v-model="question.form">
                            <div class="stepper-content">
                                <component :is="question.answer.component" :formular="question.answer" :context="context" @update="update" />
                            </div>
                            <div class="stepper-action">
                                <v-btn v-if="index + 1 < formular.length" color="primary" @click="state = getNext()">Weiter</v-btn>
                                <v-btn v-else color="primary" @click="$emit('save')">Speichern</v-btn>
                                <v-btn v-if="index > 0" text @click="state = getPrevious()">Zurück</v-btn>
                            </div>
                        </v-form>
                    </v-stepper-content>
                </template>
            </div>
        </v-stepper>
    </div>
</template>

<style lang="scss" scoped>
.stepper-content {
    padding: 1rem;
}

.stepper-action {
    display: flex;
    padding: 1rem;
    gap: 1rem;
}

.stepper-loading {
    width: 100%;
    height: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.disabled {
    opacity: 0.2;
    user-select: none;
    pointer-events: none;
}
</style>

<style lang="scss">
// div.v-stepper__step.v-stepper__step.v-stepper__step--editable.v-stepper__step--error.error--text {
//     color: $warning !important;
//     caret-color: $warning !important;
// }

div.v-stepper__step.v-stepper__step--error.error--text > div.v-stepper__label {
    color: rgba(0, 0, 0, 0.38) !important;
    font-weight: normal;
    text-shadow: none;
}

.v-stepper__content,
.v-stepper__wrapper {
    transition-duration: 200ms !important;
    transition-delay: 0s !important;
    // transition-timing-function: ease !important;
    transition-timing-function: linear !important;
    // transition: none !important;
}

// .v-stepper__wrapper {
//     transition: none !important;
// }
</style>
