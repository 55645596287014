import Vue, { VueConstructor } from "vue";
import { Depend } from "../QuestionFormular";

export default abstract class Answer {
    public id: string;
    public data: any;
    public options: AnswerOption;
    public component: string;

    constructor(id: string, options: AnswerOption, component: VueConstructor) {
        this.id = id;
        this.options = options;
        this.component = component.name;
        Vue.component(component.name, component);
    }

    render() {
        return this.options;
    }

    default(context) {
        if (this.options.default == undefined) return undefined;
        if (typeof this.options.default == "function") return this.options.default(context);
        return this.options.default;
    }
}

export interface AnswerOption {
    default?: (context) => any;
    onchange?: (depend: Depend, context, value) => any;
    rules?: Array<(value: any) => string | boolean>;
    depends?: (depend: Depend) => boolean;
}
