import Formular from "@views/Formular.vue";
import QuestionForm from "@views/forms/QuestionForm.vue";

export default [
    {
        path: "/formular/:id",
        name: "Formular",
        component: Formular,
    },
    {
        path: "/forms/question/:id",
        name: "QuestionForm",
        component: QuestionForm,
    },
];

function lazyLoadView(AsyncView) {
    const AsyncHandler = () => ({
        component: AsyncView,
        delay: 200,
        timeout: 10000,
        loading: require("@components/_loading.vue").default,
        error: require("@components/_timeout.vue").default,
    });

    return Promise.resolve({
        functional: true,
        render(h, { data, children }) {
            return h(AsyncHandler, data, children);
        },
    });
}
