import Answer, { AnswerOption } from "./Answer";
import RowAnswerComponent from "../components/RowAnswer.vue";

export default class RowAnswer extends Answer {
    constructor(id: string, options: RowAnswerOptions) {
        super(id, options, RowAnswerComponent);
    }
}

export interface RowAnswerOptions extends AnswerOption {
    items: Array<Answer>;
}
