<script>
export default {
    name: "HomeLayout",
};
</script>

<template>
    <div class="home-layout">
        <div class="content">
            <slot />
        </div>
    </div>
</template>

<style lang="scss" scoped>
.home-layout {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .content {
        width: 100%;
        max-width: 1200px;
        height: 100%;
        display: grid;
        gap: 1rem;
        overflow-y: auto;
        margin: 2rem;
        background: white;
    }
}

@media screen and (max-width: 650px) {
    .home-layout {
        .content {
            margin: 0;
        }
    }
}
</style>
