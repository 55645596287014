<script>
import ColumnAnswer from "../framework/ColumnAnswer";

import answerMixin from "@mixin/answer.mixin";

export default {
    name: "ColumnAnswerComponent",
    mixins: [answerMixin],
    methods: {
        compare(instance) {
            return instance instanceof ColumnAnswer;
        },
    },
};
</script>

<template>
    <div v-if="state" class="column-answer">
        <component
            :is="item.component"
            v-for="(item, index) in attrs.items"
            :key="index"
            :formular="item"
            :context="context"
            @update="$emit('update')"
        />
    </div>
</template>

<style lang="scss" scoped>
.column-answer {
    display: flex;
    flex-direction: column;

    * {
        flex: 1;
    }
}
</style>
