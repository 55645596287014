import Answer, { AnswerOption } from "./Answer";
import ColumnAnswerComponent from "../components/ColumnAnswer.vue";

export default class ColumnAnswer extends Answer {
    constructor(id: string, options: ColumnAnswerOptions) {
        super(id, options, ColumnAnswerComponent);
    }
}

export interface ColumnAnswerOptions extends AnswerOption {
    items: Array<Answer>;
}
