import Vue from "vue";
import Vuex from "vuex";

import ui_store, { UiState } from "./modules/ui.store";

Vue.use(Vuex);

export interface State {
    ui: UiState;
}

export default new Vuex.Store<State>({
    modules: {
        ui: ui_store,
    },
});
