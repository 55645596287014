export interface UiState {
    log: Array<LogMessage>;
}

export interface LogMessage {
    logLevel: number;
    timestamp: number;
    message: string;
}

export default {
    namespaced: true,
    state: (): UiState => ({
        log: [],
    }),
    mutations: {
        logMessage(state, { logLevel, message }) {
            state.log.push({ logLevel, timestamp: Date.now(), message });
        },
        error(state, message) {
            state.log.push({ logLevel: 0, timestamp: Date.now(), message });
        },
        warning(state, message) {
            state.log.push({ logLevel: 1, timestamp: Date.now(), message });
        },
        log(state, message) {
            state.log.push({ logLevel: 4, timestamp: Date.now(), message });
        },
    },
    actions: {},
    modules: {},
};
