import Answer, { AnswerOption } from "./Answer";
import CheckboxAnswerComponent from "../components/CheckboxAnswer.vue";

export default class CheckboxAnswer extends Answer {
    constructor(id: string, options: CheckboxAnswerOptions) {
        super(id, options, CheckboxAnswerComponent);
    }
}

export interface CheckboxAnswerOptions extends AnswerOption {
    label?: string;
    hint?: string;
}
