import Answer from "./framework/Answer";
import CheckboxAnswer from "./framework/CheckboxAnswer";
import ColumnAnswer from "./framework/ColumnAnswer";
import ComboboxAnswer from "./framework/ComboboxAnswer";
import RowAnswer from "./framework/RowAnswer";
import SelectAnswer from "./framework/SelectAnswer";
import TextareaAnswer from "./framework/TextareaAnswer";
import TextfieldAnswer from "./framework/TextfieldAnswer";
import ListfieldAnswer from "./framework/ListfieldAnswer";

const formular: Array<Question> = [
    {
        title: "Für wen ist die Anlage zugänglich?",
        answer: new SelectAnswer("access", {
            label: "Zugänglichkeit",
            items: [
                {
                    key: "Laien",
                    value: "Laien",
                },
                {
                    key: "Befugte Personen",
                    value: "Befugte Personen",
                },
            ],
            rules: [(v) => !!v],
        }),
        form: true,
    },
    {
        title: "Welche Anlagenbauform trifft auf die Verteilung zu?",
        answer: new SelectAnswer("system_design", {
            label: "Anlagenbauform",
            items: [
                { key: "Tableaux", value: "Geschlossene Bauform" },
                { key: "Reihenschrank einteilig", value: "Schrankbauform" },
                { key: "Reihenschrank mehrteilig", value: "Mehrfachschrankbauform" },
                { key: "AP-Kasten", value: "Kastenbauform" },
                { key: "UP-Kasten", value: "Schaltgerätekombination für Wandeinbau" },
                { key: "geschlossene Bauform (Kleinverteiler)", value: "geschlossene Bauform (Kleinverteiler)" },
            ],
            rules: [(v) => !!v],
        }),
        form: false,
    },
    {
        title: "Bemessungsspannung",
        answer: new SelectAnswer("rated_voltage", {
            label: "Bemessungsspannung",
            default: () => "3x400/230V",
            items: [
                {
                    key: "3x400/230V",
                    value: "3x400/230V",
                },
                {
                    key: "1x230V",
                    value: "1x230V",
                },
                {
                    key: "24V",
                    value: "24V"
                },
                {
                    key: "48V",
                    value: "48V"
                }
            ],
            rules: [(v) => !!v],
        }),
        form: true,
    },
    {
        title: "Bemessungsstrom",
        answer: new ListfieldAnswer("rated_current", {
            type: "number",
            label: "Bemessungsstrom (A)",
            label_alt: "Weitere Einspeisung (A)",
            rules: [
                (v) => v <= 250 || compareAnswer(formular, "access", "Befugte Personen") || "Anlage darf nicht für Laien gebaut werden (Frage 1)",
            ],
            onchange: (_, context, value) => {
                const highestValue = value.map((item) => parseInt(item)).sort().reverse()[0];
                if(!highestValue) return;
                setValue(context, "icp", getIcp(highestValue));
                setValue(context, "ipk", getIpk(highestValue));
                setValue(context, "icc", getIcc(highestValue));
            }
        }),
        form: true,
    },
    {
        title: "Befindet sich die Vorsicherung der Anlage innerhalb der Verteilung?",
        answer: new SelectAnswer("main_fuse_location", {
            label: "Vorsicherung",
            items: [
                {
                    key: "Ja",
                    value: "Ja",
                },
                {
                    key: "Nein",
                    value: "Nein",
                },
            ],
            rules: [(v) => !!v],
        }),
        form: true,
    },
    {
        title: "Typenangabe der Schaltschrankvorsicherung",
        answer: new RowAnswer(null, {
            items: [
                new ComboboxAnswer("main_fuse_type", {
                    label: "Typenangabe",
                    items: ["DII", "DIII", "NH000", "NH00", "NH1", "NH2", "NH3", "LS"],
                    rules: [(v) => !!v],
                }),
                new SelectAnswer("main_fuse_size", {
                    label: "Grösse",
                    items: [
                        { key: "10", value: 10 },
                        { key: "16", value: 16 },
                        { key: "20", value: 20 },
                        { key: "25", value: 25 },
                    ],
                    rules: [(v) => !!v],
                    depends: ({ compare }) => compare("main_fuse_type", "DII"),
                }),
                new SelectAnswer("main_fuse_size", {
                    label: "Grösse",
                    items: [
                        { key: "10", value: 10 },
                        { key: "16", value: 16 },
                        { key: "20", value: 20 },
                        { key: "25", value: 25 },
                        { key: "35 (40)", value: 35 },
                        { key: "50", value: 50 },
                        { key: "63", value: 63 },
                    ],
                    rules: [(v) => !!v],
                    depends: ({ compare }) => compare("main_fuse_type", "DIII"),
                }),
                new SelectAnswer("main_fuse_size", {
                    label: "Grösse",
                    items: [
                        { key: "10", value: 10 },
                        { key: "16", value: 16 },
                        { key: "20", value: 20 },
                        { key: "25", value: 25 },
                        { key: "32", value: 32 },
                        { key: "35", value: 35 },
                        { key: "40", value: 40 },
                        { key: "50", value: 50 },
                        { key: "63", value: 63 },
                        { key: "80", value: 80 },
                        { key: "100", value: 100 },
                    ],
                    rules: [(v) => !!v],
                    depends: ({ compare }) => compare("main_fuse_type", "NH000"),
                }),
                new SelectAnswer("main_fuse_size", {
                    label: "Grösse",
                    items: [
                        { key: "10", value: 10 },
                        { key: "16", value: 16 },
                        { key: "20", value: 20 },
                        { key: "25", value: 25 },
                        { key: "32", value: 32 },
                        { key: "35", value: 35 },
                        { key: "40", value: 40 },
                        { key: "50", value: 50 },
                        { key: "63", value: 63 },
                        { key: "80", value: 80 },
                        { key: "100", value: 100 },
                        { key: "125", value: 125 },
                        { key: "160", value: 160 },
                    ],
                    rules: [(v) => !!v],
                    depends: ({ compare }) => compare("main_fuse_type", "NH00"),
                }),
                new ComboboxAnswer("main_fuse_size", {
                    label: "Grösse",
                    items: [63, 80, 100, 125, 160, 200, 224, 250],
                    type: "number",
                    rules: [(v) => !!v],
                    depends: ({ compare }) => compare("main_fuse_type", "NH1"),
                }),
                new ComboboxAnswer("main_fuse_size", {
                    label: "Grösse",
                    items: [63, 80, 100, 125, 160, 200, 224, 250, 300, 315, 355, 400, 425, 455],
                    type: "number",
                    rules: [(v) => !!v],
                    depends: ({ compare }) => compare("main_fuse_type", "NH2"),
                }),
                new ComboboxAnswer("main_fuse_size", {
                    label: "Grösse",
                    items: [63, 80, 100, 125, 160, 200, 224, 250, 300, 315, 355, 400, 425, 455, 500, 630],
                    type: "number",
                    rules: [(v) => !!v],
                    depends: ({ compare }) => compare("main_fuse_type", "NH3"),
                }),
                new ComboboxAnswer("main_fuse_size", {
                    label: "Grösse",
                    items: [13, 16, 20, 25, 32, 35, 40, 50, 63, 80, 100, 125],
                    type: "number",
                    rules: [(v) => !!v],
                    depends: ({ compare }) => compare("main_fuse_type", "LS"),
                }),
                new TextfieldAnswer("main_fuse_size", {
                    label: "Grösse",
                    type: "number",
                    rules: [(v) => !!v],
                    depends: ({ compare }) =>
                        !compare("main_fuse_type", "DII") &&
                        !compare("main_fuse_type", "DIII") &&
                        !compare("main_fuse_type", "NH000") &&
                        !compare("main_fuse_type", "NH00") &&
                        !compare("main_fuse_type", "NH1") &&
                        !compare("main_fuse_type", "NH2") &&
                        !compare("main_fuse_type", "NH3") &&
                        !compare("main_fuse_type", "LS"),
                }),
            ],
        }),
        form: true,
    },
    {
        title: "Kurzschlussfestigkeit (Ipk oder Icp)",
        answer: new RowAnswer(null, {
            items: [
                new SelectAnswer("short_circuit_resistance_simple", {
                    label: "Angabe",
                    default: () => "Ipk",
                    items: [
                        {
                            key: "Ipk",
                            value: "Ipk",
                        },
                        {
                            key: "Icp",
                            value: "Icp",
                        },
                    ],
                    rules: [(v) => !!v],
                }),
                new TextfieldAnswer("ipk", {
                    label: "Bemessungsstossstromfestigkeit (kA)",
                    type: "number",
                    default: (context) => getIpk(getAnswer(context, "rated_current").sort().reverse()[0]) || 17,
                    rules: [(v) => !!v],
                    depends: ({ compare }) => compare("short_circuit_resistance_simple", "Ipk"),
                }),
                new TextfieldAnswer("icp", {
                    label: "unbeeinflusster Kurzschlussstrom (kA)",
                    type: "number",
                    default: (context) => getIcp(getAnswer(context, "rated_current").sort().reverse()[0]) || 10,
                    rules: [(v) => !!v],
                    depends: ({ compare }) => compare("short_circuit_resistance_simple", "Icp"),
                }),
            ],
            depends: ({ compare }) => compare("main_fuse_location", "Ja"),
        }),
        depends: ({ compare }) => compare("main_fuse_location", "Ja"),
        form: true,
    },
    {
        title: "Kurzschlussfestigkeit (Icc oder Icw)",
        answer: new RowAnswer(null, {
            items: [
                new SelectAnswer("short_circuit_resistance_complex", {
                    label: "Angabe",
                    default: () => "Icc",
                    items: [
                        {
                            key: "Icc",
                            value: "Icc",
                        },
                        {
                            key: "Icw",
                            value: "Icw",
                        },
                    ],
                    rules: [(v) => !!v],
                }),
                new TextfieldAnswer("icc", {
                    label: "Bedingter Bemessungskurzschlussstrom Icc (kA)",
                    type: "number",
                    default: (context) => getIcc(getAnswer(context, "rated_current").sort().reverse()[0]) || 6,
                    rules: [(v) => !!v],
                    depends: ({ compare }) => compare("short_circuit_resistance_complex", "Icc"),
                }),
                new ColumnAnswer(null, {
                    items: [
                        new TextfieldAnswer("icw", {
                            label: "Bemessungskurzzeitstromfestigkeit Icw (kA)",
                            type: "number",
                            rules: [(v) => !!v],
                        }),
                        new TextfieldAnswer("icw_time", {
                            label: "Zeitwert (sek)",
                            type: "number",
                            rules: [(v) => !!v],
                        }),
                        new TextfieldAnswer("ipk", {
                            label: "Bemessungsstossstromfestigkeit Ipk (kA)",
                            type: "number",
                            rules: [(v) => !!v],
                        }),
                    ],
                    depends: ({ compare }) => compare("short_circuit_resistance_complex", "Icw"),
                }),
            ],
            depends: ({ compare }) => compare("main_fuse_location", "Nein"),
        }),
        depends: ({ compare }) => compare("main_fuse_location", "Nein"),
        form: true,
    },
    {
        title: "Wie viele Sicherungen sind in der Anlage verbaut?",
        answer: new ColumnAnswer(null, {
            items: [
                new SelectAnswer("fuse_amount", {
                    label: "Anzahl Sicherungen",
                    default: () => 0.6,
                    items: [
                        { key: "1 Sicherung", value: 1 },
                        { key: "2-3 Sicherungen", value: 0.9 },
                        { key: "4-5 Sicherungen", value: 0.8 },
                        { key: "6-9 Sicherungen", value: 0.7 },
                        { key: "10 oder mehr Sicherungen", value: 0.6 },
                    ],
                    rules: [(v) => !!v],
                    depends: ({ compare }) => compare("access", "Befugte Personen"),
                }),
                new SelectAnswer("fuse_amount", {
                    label: "Anzahl Sicherungen",
                    default: () => 0.5,
                    items: [
                        { key: "1 Sicherung", value: 1 },
                        { key: "2-3 Sicherungen", value: 0.8 },
                        { key: "4-5 Sicherungen", value: 0.7 },
                        { key: "6-9 Sicherungen", value: 0.6 },
                        { key: "10 oder mehr Sicherungen", value: 0.5 },
                    ],
                    rules: [(v) => !!v],
                    depends: ({ compare }) => compare("access", "Laien"),
                }),
            ],
            depends: ({ compare }) => compare("access", "Befugte Personen") || compare("access", "Laien"),
        }),
        depends: ({ compare }) => compare("access", "Befugte Personen") || compare("access", "Laien"),
        form: true,
    },
    {
        title: "Wo wird die Anlage montiert?",
        answer: new SelectAnswer("mounting_location", {
            label: "Montageort",
            items: [
                { key: "Innenraumaufstellung", value: "Innenraumaufstellung" },
                { key: "Freiluftaufstellung", value: "Freiluftaufstellung" },
            ],
            rules: [(v) => !!v],
        }),
        form: true,
    },
    {
        title: "Erfolgt die Einspeisung über das Niederspannungsnetz?",
        answer: new SelectAnswer("low_voltage_grid", {
            label: "Einspeisung ab Niederspannungsnetz",
            hint: `Falls es sich bei der Anlage um eine Hauptverteilung, Unterverteilung, EFH, MFH, Steuerungsverteiler oder Zählerkasten handelt, wird diese Frage mit Ja beantwortet`,
            items: [
                { key: "Ja", value: "Umgebung B" },
                { key: "Nein", value: "Umgebung A" },
            ],
            rules: [(v) => !!v],
        }),
        depends: ({ compare }) => !compare("system_design", "Kleinverteiler") && !compare("system_design", "geschlossene Bauform (Kleinverteiler)"),
        form: true,
    },
    {
        title: "Welche besonderen Betriebsbedingungen werden an die Anlage gestellt?",
        answer: new ColumnAnswer(null, {
            items: [
                new CheckboxAnswer("halogenfree", {
                    label: "halogenfrei",
                    hint: "es können zusätzliche kosten anfallen",
                }),
                new CheckboxAnswer("temperature", {
                    label: "Umgebungstemperatur über 40°c",
                }),
                new CheckboxAnswer("humidity", {
                    label: "Hohe Luftfeuchtigkeit (mehr als 50%)",
                }),
                new CheckboxAnswer("altitude", {
                    label: "Höhenlage höher als 2000 m.ü.M",
                }),
                new CheckboxAnswer("temp_hum_change", {
                    label: "Schnelle Temperatur und oder Luftdruckänderungen",
                }),
                new CheckboxAnswer("dust", {
                    label: "Staubige Umgebung (z.B. Schreinerei)",
                }),
                new CheckboxAnswer("magnetic_field", {
                    label: "Einwirkung starker elektrischer oder magnetischer Felder",
                }),
                new CheckboxAnswer("explosive", {
                    label: "Montageort in feuer oder explosionsgefährdeten Bereichen",
                }),
            ],
        }),
        form: true,
    },
    {
        title: "Ist ein Kupfersammelschienensystem vorhanden?",
        answer: new SelectAnswer("copper_system", {
            label: "Kupfersammelschienensystem",
            items: [
                { key: "Ja", value: "Form 2a" },
                { key: "Nein", value: "Form 2b" },
            ],
            rules: [(v) => !!v],
            depends: ({ compare }) => compare("access", "Befugte Personen"),
        }),
        depends: ({ compare }) => compare("access", "Befugte Personen"),
        form: true,
    },
    {
        title: "Schutzart",
        answer: new ColumnAnswer(null, {
            items: [
                new SelectAnswer("protection", {
                    label: "Schutzart",
                    items: [
                        { key: "IP2XC", value: "IP2XC" },
                        { key: "IP2XB", value: "IP2XB" },
                    ],
                    rules: [(v) => !!v],
                    depends: ({ compare }) => compare("access", "Befugte Personen"),
                }),
                // Reihenschrank
                new TextfieldAnswer("ip_protection", {
                    label: "Gehäuse IP-Schutzart",
                    rules: [(v) => /^IP(?:[2-6]|5K|6K)(?:[0-9X]|4K|6K|9K)(?:[ABCD])?$/i.test(v) || "Ungültiges Format (z.B. IP20 oder höher)"],
                    default: () => "IP40",
                    depends: ({ compare }) => compare("system_design", "Schrankbauform") || compare("system_design", "Mehrfachschrankbauform"),
                }),
                // AP Kasten bei Freiluftaufstellung
                new TextfieldAnswer("ip_protection", {
                    label: "Gehäuse IP-Schutzart",
                    rules: [
                        (v) =>
                            /^IP(?:43|44|45|46|47|48|49|(?:[5-6]|5K|6K)(?:[0-9X]|4K|6K|9K))(?:[ABCD])?/i.test(v) ||
                            "Ungültiges Format (z.B. IP20 oder höher)",
                    ],
                    default: () => "IP54",
                    depends: ({ compare }) => compare("system_design", "Kastenbauform") && compare("mounting_location", "Freiluftaufstellung"),
                }),
                // AP Kasten bei Innenraumaufstellung
                new TextfieldAnswer("ip_protection", {
                    label: "Gehäuse IP-Schutzart",
                    rules: [(v) => /^IP(?:[2-6]|5K|6K)(?:[0-9X]|4K|6K|9K)(?:[ABCD])?$/i.test(v) || "Ungültiges Format (z.B. IP20 oder höher)"],
                    default: () => "IP40",
                    depends: ({ compare }) => compare("system_design", "Kastenbauform") && !compare("mounting_location", "Freiluftaufstellung"),
                }),
                // UP Kasten bei Freiluftaufstellung
                new TextfieldAnswer("ip_protection", {
                    label: "Gehäuse IP-Schutzart",
                    rules: [
                        (v) =>
                            /^IP(?:43|44|45|46|47|48|49|(?:[5-6]|5K|6K)(?:[0-9X]|4K|6K|9K))(?:[ABCD])?/i.test(v) ||
                            "Ungültiges Format (z.B. IP20 oder höher)",
                    ],
                    default: () => "IP43",
                    depends: ({ compare }) =>
                        compare("system_design", "Schaltgerätekombination für Wandeinbau") && compare("mounting_location", "Freiluftaufstellung"),
                }),
                // UP Kasten bei Innenraumaufstellung
                new TextfieldAnswer("ip_protection", {
                    label: "Gehäuse IP-Schutzart",
                    rules: [(v) => /^IP(?:[2-6]|5K|6K)(?:[0-9X]|4K|6K|9K)(?:[ABCD])?$/i.test(v) || "Ungültiges Format (z.B. IP20 oder höher)"],
                    default: () => "IP40",
                    depends: ({ compare }) =>
                        compare("system_design", "Schaltgerätekombination für Wandeinbau") && !compare("mounting_location", "Freiluftaufstellung"),
                }),
                // Kleinverteiler
                new TextfieldAnswer("ip_protection", {
                    label: "Gehäuse IP-Schutzart",
                    rules: [(v) => /^IP(?:[2-6]|5K|6K)(?:[0-9X]|4K|6K|9K)(?:[ABCD])?$/i.test(v) || "Ungültiges Format (z.B. IP20 oder höher)"],
                    default: () => "IP40",
                    depends: ({ compare }) => compare("system_design", "Kleinverteiler") || compare("system_design", "geschlossene Bauform (Kleinverteiler)"),
                }),
            ],
        }),
        depends: ({ compare }) =>
            compare("access", "Befugte Personen") || (!compare("system_design", "Geschlossene Bauform") && !compare("system_design", undefined)),
        form: true,
    },
    {
        title: "Können alle Sicherungen unter Spannung ausgetauscht werden?",
        answer: new ColumnAnswer(null, {
            items: [
                new SelectAnswer("fuse_change", {
                    label: "Sicherungen austauschbar",
                    items: [
                        { key: "Ja", value: "herausnehmbare Teile oder Einschübe" },
                        { key: "Nein", value: "Einsätze" },
                    ],
                    rules: [(v) => !!v],
                    depends: ({ compare }) => !compare("access", "Laien"),
                }),
                new SelectAnswer("fuse_change", {
                    label: "Sicherungen austauschbar",
                    items: [
                        { key: "Ja", value: "herausnehmbare Teile" },
                        { key: "Nein", value: "Einsätze" },
                    ],
                    rules: [(v) => !!v],
                    depends: ({ compare }) => compare("access", "Laien"),
                }),
            ],
        }),
        form: true,
    },
    {
        title: "Anlagegrösse",
        answer: 
            new ColumnAnswer(null, {
                items: [
                    new RowAnswer(null, {
                        items: [
                            new TextfieldAnswer("width", {
                                label: "breite (mm)",
                                type: "number",
                                rules: [(v) => !!v],
                            }),
                            new TextfieldAnswer("height", {
                                label: "höhe (mm)",
                                type: "number",
                                rules: [(v) => !!v],
                            }),
                            new TextfieldAnswer("depth", {
                                label: "tiefe (mm)",
                                type: "number",
                                rules: [(v) => !!v],
                            }),
                        ],
                    }),
                    new RowAnswer(null, {
                        items: [
                            new TextfieldAnswer("weight", {
                                label: "Gewicht (kg)",
                                hint: "optional",
                                type: "number"
                            })
                        ]
                    })
                ]
            }),
        form: true,
    },
    {
        title: "Kann die Anlage problemlos an ihre Montageposition transportiert werden?",
        answer: new ColumnAnswer(null, {
            items: [
                new SelectAnswer("transportable", {
                    label: "transportierbar",
                    items: [
                        { key: "Ja", value: "Ja" },
                        { key: "Nein", value: "Nein" },
                    ],
                    rules: [(v) => !!v],
                }),
                new ComboboxAnswer("transport_conditions", {
                    label: "Beim Transport zu beachten",
                    items: ["Anlage Trennen"],
                    multiple: true,
                    chips: true,
                    "deletable-chips": true,
                    rules: [(v) => !!v],
                    depends: ({ compare }) => compare("transportable", "Nein"),
                }),
            ],
        }),
        form: true,
    },
    {
        title: "Wird die Anlage von der Firma D.Fallegger Elektro-Tableaux AG montiert?",
        answer: new SelectAnswer("assembly", {
            label: "Montage",
            items: [
                { key: "Ja", value: "Ja" },
                { key: "Nein", value: "Nein" },
            ],
            rules: [(v) => !!v],
        }),
        form: true,
    },
    {
        title: "Verteilnetzbetreiber",
        answer: new ComboboxAnswer("power_station", {
            label: "EVU",
            items: (axios) =>
                new Promise<Array<string>>((resolve, reject) => {
                    axios
                        .get("/external/evu")
                        .then((res) => resolve(res.data.map((item) => item.evu_id)))
                        .catch(() => reject());
                }),
            rules: [(v) => !!v],
        }),
        form: true,
    },
    {
        title: "Kundenwünsche",
        answer: new TextareaAnswer("customer_request", {
            label: "Kundenwünsche",
            placeholder: "Schrankbezeichnung, Schrankfarbe, Spezielles Schloss, Halogenfrei...",
            rows: 8,
        }),
        form: true,
    },
];
export default formular;

export interface Question {
    title: string;
    answer: Answer;
    depends?: (depend: Depend) => boolean;
    form: boolean;
}

export interface Depend {
    compare: (id: string, value: any) => boolean;
    display: (id: string) => boolean;
}

export const dependFunction = (context) => ({
    compare: (id, answer) => compareAnswer(context, id, answer),
    display: (id) => displayAnswer(context, id),
});

export function absolutToRelativContext(context) {
    if (!Array.isArray(context)) throw new Error("context is not an array");
    if (!context[0]) throw new Error("context is empty");
    if (context[0].answer && context[0].answer instanceof Answer) context = context.map((item) => item.answer);
    if (!(context[0] instanceof Answer)) throw new Error("no answers found");
    return context;
}

export function compareAnswer(context, id, answer) {
    context = absolutToRelativContext(context);
    for (const question of context) {
        if (question.options && question.options.items && question.options.items[0] instanceof Answer)
            if (compareAnswer(question.options.items, id, answer)) return true;
        if (question.id == id && question.data) {
            return question.data == answer;
        }
    }
    return false;
}

export function getAnswer(context, id) {
    context = absolutToRelativContext(context);
    for (const question of context) {
        if (question.options && question.options.items && question.options.items[0] instanceof Answer) return getAnswer(question.options.items, id);
        if (question.id == id && question.data) {
            return question.data;
        }
    }
    return undefined;
}

export function displayAnswer(context, id) {
    context = absolutToRelativContext(context);
    for (const question of context) {
        if (question.options && question.options.items && question.options.items[0] instanceof Answer)
            if (displayAnswer(question.options.items, id)) return true;
        if (question.id == id) {
            if (question.options && question.options.depends) {
                return question.options.depends(dependFunction(context));
            }
            return true;
        }
    }
    return false;
}

export function setValue(context, id, value) {
    context = absolutToRelativContext(context);
    for (const question of context) {
        if (question.options && question.options.items && question.options.items[0] instanceof Answer) setValue(question.options.items, id, value);
        if (question.id == id) {
            question.data = value;
        }
    }
}

export function getNext(context, index) {
    for (let i = index; i < context.length; i++) {
        const question = context[i];
        if (question.depends) {
            if (question.depends(dependFunction(context))) return i + 1;
        } else return i + 1;
    }
    return context.length;
}

export function getPrevious(context, index) {
    for (let i = index - 2; i > 0; i--) {
        const question = context[i];
        if (question.depends) {
            if (question.depends(dependFunction(context))) return i + 1;
        } else return i + 1;
    }
    return 1;
}

export function getData(context, root?) {
    let result = {};
    context = absolutToRelativContext(context);
    if (!root) root = context;
    for (const question of context) {
        if (question.options && question.options.items && question.options.items[0] instanceof Answer) {
            if (question.options?.depends == undefined || question.options.depends(dependFunction(context)) == true)
                result = { ...result, ...getData(question.options.items, root) };
        } else if (question.options?.depends == undefined || question.options.depends(dependFunction(root)) == true)
            result[question.id] = question.data;
    }
    return result;
}

export function mapData(context, data) {
    context = absolutToRelativContext(context);
    for (const question of context) {
        if (question.options && question.options.items && question.options.items[0] instanceof Answer) {
            mapData(question.options.items, data);
        } else {
            if (data && data[question.id]) question.data = data[question.id];
        }
    }
    return context;
}

export function getIcp(fuse: number) {
    if (fuse <= 63) return 6;
    if (fuse <= 125) return 10;
    if (fuse <= 250) return 15;
    if (fuse <= 355) return 20;
    if (fuse <= 500) return 25;
    if (fuse > 500) return 30;
    return null;
}

export function getIpk(fuse: number) {
    if (fuse <= 63) return 10;
    if (fuse <= 125) return 17;
    if (fuse <= 250) return 30;
    if (fuse <= 355) return 40;
    if (fuse <= 500) return 50;
    if (fuse > 500) return 63;
    return null;
}

export function getIcc(fuse) {
    if (fuse <= 63) return 6;
    if (fuse <= 125) return 10;
    if (fuse <= 250) return 15;
    if (fuse <= 355) return 20;
    if (fuse <= 500) return 25;
    if (fuse > 500) return 30;
    return null;
}
