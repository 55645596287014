<script>
import QuestionForm from "@components/question/QuestionForm.vue";

export default {
    name: "QuestionFormView",
    components: {
        QuestionForm,
    },
    data() {
        return {
            formular: undefined,
            locked: false,
        };
    },
    computed: {
        formular_id() {
            return this.$route.params.id;
        },
    },
    mounted() {
        this.axios
            .get("/external/formular/" + this.formular_id)
            .then((res) => {
                this.$refs.question_form.setData(res.data);
            })
            .catch((err) => {
                if (err?.response?.status == 406) this.locked = true;
            });

        window.addEventListener("message", this.handleEvent);
    },
    beforeDestroy() {
        window.removeEventListener("message", this.handleEvent);
    },
    methods: {
        save() {
            this.axios
                .post("/external/formular/" + this.formular_id + window.location.search, this.formular)
                .then(() => parent.postMessage({ type: "save", id: this.formular_id }, "*"))
                .catch(() => parent.postMessage({ type: "error", id: this.formular_id }, "*"));
        },
        handleEvent(message) {
            if (message.data?.id == this.formular?.reference_id) {
                if (message.data.type == "save") this.save();
            }
        },
    },
};
</script>

<template>
    <div class="form-wrapper" :class="{ locked }">
        <div v-if="locked" class="locked">Auftrag geschlossen</div>
        <QuestionForm ref="question_form" v-model="formular" :disabled="locked" @save="save" />
    </div>
</template>

<style lang="scss" scoped>
.form-wrapper {
    width: 100vw;
    height: 100vh;
    overflow-y: auto;
    background: white;

    .locked {
        position: absolute;
        top: 50%;
        left: 0;
        width: 100vw;
        z-index: 100;
        transform: translateY(-50%);
        font-size: 2rem;
        background: $error;
        color: white;
        padding: 2rem;
        box-shadow: $box-shadow-3;
        text-align: center;
    }
}

.form-wrapper.locked {
    overflow: hidden;
}
</style>
