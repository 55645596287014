<script>
import Vue from "vue";
import { mapState } from "vuex";

export default Vue.extend({
    name: "App",
    data() {
        return {
            logMessage: undefined,
            logColor: undefined,
        };
    },
    computed: {
        ...mapState({
            log: (state) => state.ui.log,
        }),
    },
    watch: {
        $route: {
            immediate: true,
            handler(to) {
                document.title = `DFutura - ${to.name}`;
            },
        },
        log(value) {
            this.logMessage = value[value.length - 1];
            switch (this.logMessage.logLevel) {
                case 0:
                    this.logColor = "error";
                    break;
                case 1:
                    this.logColor = "warning";
                    break;
                case 3:
                    this.logColor = "info";
                    break;
                default:
                    this.logColor = "success";
                    break;
            }
        },
    },
});
</script>

<template>
    <v-app id="app">
        <v-main>
            <router-view :key="$route.fullPath" />
            <v-snackbar v-if="logMessage" v-model="logMessage" timeout="5000" :color="logColor">{{ logMessage.message }}</v-snackbar>
        </v-main>
    </v-app>
</template>

<style lang="scss">
#app {
    width: 100vw;
    height: 100vh;
    background: $background;
}
</style>
