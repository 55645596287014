<script>
import TextareaAnswer from "../framework/TextareaAnswer";

import answerMixin from "@mixin/answer.mixin";

export default {
    name: "TextareaAnswerComponent",
    mixins: [answerMixin],
    methods: {
        compare(instance) {
            return instance instanceof TextareaAnswer;
        },
    },
};
</script>

<template>
    <v-textarea v-if="state" v-model="value" outlined persistent-hint v-bind="attrs" />
</template>

<style lang="scss" scoped></style>
